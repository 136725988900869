<template>
  <div>
    <div class="home_header">
      <div class="w">
        <h1 class="left" @click="goHome">
          <img src="../../../../../assets/images/home/logo.png" alt="" />
          <span>灯云|金融数字化直播</span>
        </h1>
        <div class="nav">
          <div>
            <span
              :class="routerName == 'index' ? 'active' : ''"
              @click="jumpPage('index')"
              >首页</span
            >
          </div>
          <div>
            <span
              :class="routerName == 'scene' ? 'active' : ''"
              @click="jumpPage('scene')"
              >使用场景</span
            >
          </div>
          <!-- <div>
            <span>
              <a
                href="https://tanzhiying.feishu.cn/docx/doxcnLdA90HaDwfOLytVO73Nk9d"
                target="_blank"
                >安装插件</a
              >
            </span>
          </div> -->
          <div>
            <span
              :class="routerName == 'aboutUs' ? 'active' : ''"
              @click="jumpPage('aboutUs')"
              >关于我们</span
            >
          </div>
          <!-- https://tanzhiying.feishu.cn/docx/doxcnLdA90HaDwfOLytVO73Nk9d -->
        </div>
      </div>
    </div>
    <div class="blank"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      routerName: "index",
    };
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/index" });
    },
    jumpPage(path) {
      if (this.routerName === path) return;
      this.routerName = path;
      this.$router.push({ path: `/${path}` });
    },
  },
  mounted() {
    this.routerName = this.$route.name;
  },
};
</script>
<style lang="less" scoped>
.blank {
  width: 100%;
  height: 74px;
}
.home_header {
  width: 100%;
  display: flex;
  padding: 12px 0;
  box-sizing: border-box;
  color: #2354f4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: #fff;
  .w {
    width: 1200px;
    margin: auto;
    display: flex;
  }
  .left {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 12px;

    &:hover {
      color: #409eff;
    }
    > img {
      height: 50px;
      width: auto;
      margin-right: 12px;
    }
    > span {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    margin-left: 50px;
    justify-content: space-around;
    font-size: 16px;
    font-weight: bold;
    a {
      text-decoration: none;
      color: #2354f4;
    }
    div {
      width: 150px;
      text-align: center;
      position: relative;
      span:hover {
        color: #409eff;
        cursor: pointer;
      }
      .active::after {
        content: "";
        width: 30px;
        height: 3px;
        border-radius: 1.5px;
        background: #2354f4;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>