<!-- 外层 -->
<template>
  <div class="layout-wrapper">
    <keep-alive>
      <Header></Header>
    </keep-alive>
    <div class="body">
      <router-view />
    </div>
    <keep-alive>
      <Footer></Footer>
    </keep-alive>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  components: {
    Header,
    Footer,
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.layout-wrapper {
  background:#3d3e49;
}
</style>
